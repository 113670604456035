/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import { Auth } from "aws-amplify";

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "company-staging",
            "region": "eu-west-2"
        },
        {
            "tableName": "channel",
            "region": "eu-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "unloackreports231427-staging",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "settingsapi",
            "endpoint": "https://x2ezmfob51.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "adminpanel",
            "endpoint": "https://79u71y3we9.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:e632c722-5aa1-463c-a736-6087caf56d93",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_L3Co1KZe1",
    "aws_user_pools_web_client_id": "39k5aj7n2vuebv3neks1qoknhp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],

};


export default awsmobile;
