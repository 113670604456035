import { Auth } from 'aws-amplify';
import { useState, createContext, useEffect } from 'react';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authError, setAuthError] = useState('');
  
  useEffect(() => {
    getStatus();
  }, []);
  const getStatus=()=>{
    Auth.currentAuthenticatedUser().then(result=>{
        if(result?.username){
            setUser(result)
        }
    })
  }
  return (
    <UserContext.Provider value={{ user, setUser, authError, setAuthError }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
