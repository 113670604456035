
export const ROUTES = {
  DASHBOARD: "/dashboard",
  COMMAND:"/command",
  COMPLIANCE:"/compliance",
  USER:"/user",
  LOGIN:"/login",
  AGENT:"agent"
};

export const BACKENDROUTES={
  GETCOMPANY:"/get-company",
  GETCOMMAND:"/command",
}