import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../constant/index.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
const About = lazy(() => import("../views/About.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));


const User = lazy(() => import("../views/ui/user"));
const Command = lazy(() => import("../views/ui/command"));
const Agent = lazy(() => import("../views/ui/Agent"));
const Compliance = lazy(() => import("../views/ui/compliance"));
const Login = lazy(() => import("../views/Login"));

/*****Routes******/

const ThemeRoutes = [
  
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to={ROUTES.DASHBOARD} /> },
      { path: ROUTES.DASHBOARD, exact: true, element: <Starter /> },
      { path: ROUTES.COMMAND, exact: true, element: <Command /> },
      { path: ROUTES.COMPLIANCE, exact: true, element: <Compliance /> },
      { path: ROUTES.USER, exact: true, element: <User /> },
      { path: ROUTES.LOGIN, exact: true, element: <Login /> },
      { path: ROUTES.AGENT, exact: true, element: <Agent /> },
      { path: "/starter", exact: true, element: <Starter /> },
      { path: "/about", exact: true, element: <About /> },
      { path: "/alerts", exact: true, element: <Alerts /> },
      { path: "/badges", exact: true, element: <Badges /> },
      { path: "/buttons", exact: true, element: <Buttons /> },
      { path: "/cards", exact: true, element: <Cards /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },
    ],
  }
];

export default ThemeRoutes;
